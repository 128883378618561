import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Bimetal = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>
						隔測式温度計（注文製作品：接点付）
						<br class="sp" />
						図面（PDFファイル）
					</h2>
					<div className="content-container">
						<ul className="documents-ul flex wrap">
						<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lbw-130e.pdf" target={"_blank"} rel="noreferrer">
									接点付・防水型 LBW-130E
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lbw-130eb.pdf" target={"_blank"} rel="noreferrer">
									接点付・防水型端子箱付 LBW-130EB
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lbw-130ms.pdf" target={"_blank"} rel="noreferrer">
									接点付・防水型 LBW-130MS
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lbw-130msb.pdf" target={"_blank"} rel="noreferrer">
									接点付・防水型端子箱付 LBW-130MSB
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lb-100se.pdf" target={"_blank"} rel="noreferrer">
									接点付・壁掛型 LB-100SE
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/las-100se.pdf" target={"_blank"} rel="noreferrer">
									接点付・直結式S型 LAS-100SE
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lal-100se.pdf" target={"_blank"} rel="noreferrer">
									接点付・直結式L型 LAL-100SE
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lat-100se.pdf" target={"_blank"} rel="noreferrer">
									接点付・直結式T型 LAT-100SE
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/ld-100se.pdf" target={"_blank"} rel="noreferrer">
									接点付・埋没式 LD-100SE
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lb-75e.pdf" target={"_blank"} rel="noreferrer">
									接点付・壁掛型 LB-75E
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/laf-100se.pdf" target={"_blank"} rel="noreferrer">
									接点付・直結式F型 LAF-100SE
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/ld-75e.pdf" target={"_blank"} rel="noreferrer">
									接点付・埋込型 LD-75E
								</a>
							</li>
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Bimetal;
